exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lwd-service-js": () => import("./../../../src/pages/lwd-service.js" /* webpackChunkName: "component---src-pages-lwd-service-js" */),
  "component---src-pages-maintainence-js": () => import("./../../../src/pages/maintainence.js" /* webpackChunkName: "component---src-pages-maintainence-js" */),
  "component---src-pages-photo-index-js": () => import("./../../../src/pages/photo/index.js" /* webpackChunkName: "component---src-pages-photo-index-js" */),
  "component---src-pages-photo-strapi-photo-gallery-slug-js": () => import("./../../../src/pages/photo/{strapiPhotoGallery.Slug}.js" /* webpackChunkName: "component---src-pages-photo-strapi-photo-gallery-slug-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-tos-index-js": () => import("./../../../src/pages/tos/index.js" /* webpackChunkName: "component---src-pages-tos-index-js" */),
  "component---src-pages-tos-strapi-legal-page-slug-js": () => import("./../../../src/pages/tos/{strapiLegal.pageSlug}.js" /* webpackChunkName: "component---src-pages-tos-strapi-legal-page-slug-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

